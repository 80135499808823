/* eslint-disable no-shadow */
/* eslint-disable no-undef */
/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
// import useSWR from 'swr';
import { NextSeo } from "next-seo";
import { motion } from "framer-motion";
// import { useRouter } from 'next/router';
import { styled } from "@stitches/react";
// import ReactPlayer from "react-player/lazy";
import { useEffect, useRef, useState } from "react";
import Head from "next/head";
import useAudioStore from "../../store/audio.store";
import useGuestCoverStore from "../../store/guestCover.store";
import { IndexMomentCover } from "../../modules/moment/layouts/IndexMomentCover";
import { IndexMomentDetail } from "../../modules/moment/layouts/IndexMomentDetail";
// eslint-disable-next-line import/extensions
import { apiURL } from "../../utils/AppConfig";

const Moment = ({ momentData, comments, events, url, cover }) => {
  const audioRef = useRef(null);
  const iframeRef = useRef(null);
  const widgetRef = useRef(null);
  const playing = useAudioStore((state) => state.audioPlay);

  // useEffect(() => {
  //   if (audioRef.current) {
  //     if (playing) {
  //       audioRef.current.play();
  //     } else {
  //       audioRef.current.pause();
  //     }
  //   }
  // }, [playing]);

  const guestCover = useGuestCoverStore((state) => state.guestCover);
  // const guestCover = false;
  // const dataId = moment.data.length > 0 ? moment.data[0].id : null;
  const data = momentData?.length > 0 ? momentData[0] : null;
  const event = events || null;

  const [showPage, setShowPage] = useState(null);

  const backsoundUrl =
    data?.backsoundUrl === "" || !data?.backsoundUrl
      ? data?.backsound?.audioFile.url
      : data?.backsoundUrl;

  useEffect(() => {
    if (data?.backsoundUrl?.includes("soundcloud.com")) {
      const initializeSoundCloud = () => {
        if (iframeRef.current && window.SC) {
          widgetRef.current = window.SC.Widget(iframeRef.current);

          widgetRef.current.bind(window.SC.Widget.Events.READY, () => {
            // Add a small delay to ensure widget is fully initialized
            setTimeout(() => {
              if (playing) {
                widgetRef.current.play();
              } else {
                widgetRef.current.pause();
              }
            }, 300);
          });

          widgetRef.current.bind(window.SC.Widget.Events.FINISH, () => {
            if (playing) {
              widgetRef.current.seekTo(0);
              widgetRef.current.play();
            }
          });

          // Add error handling
          widgetRef.current.bind(window.SC.Widget.Events.ERROR, () => {
            console.warn("SoundCloud playback error occurred");
            // Attempt to reinitialize
            widgetRef.current.load(data.backsoundUrl, {
              auto_play: playing
            });
          });
        }
      };

      // Clean up function to properly destroy the widget
      const cleanup = () => {
        if (widgetRef.current) {
          widgetRef.current.unbind(window.SC.Widget.Events.READY);
          widgetRef.current.unbind(window.SC.Widget.Events.FINISH);
          widgetRef.current.unbind(window.SC.Widget.Events.ERROR);
        }
      };

      if (window.SC) {
        cleanup();
        initializeSoundCloud();
      } else {
        const handleScriptLoad = () => {
          cleanup();
          initializeSoundCloud();
        };
        document.addEventListener("scload", handleScriptLoad);
        return () => {
          document.removeEventListener("scload", handleScriptLoad);
          cleanup();
        };
      }
    }
    
    if (audioRef.current) {
      if (playing) {
        audioRef.current.play();
      } else {
        audioRef.current.pause();
      }
      return () => {};
    }

    return () => {};
  }, [playing, data]);

  const logoBahagiaDay =
    "https://bahagia.s3.ap-southeast-3.amazonaws.com/dev/bahagia_default_image_0f97890872.jpg";

  useEffect(() => {
    setShowPage(true);
  }, []);

  if (!data) {
    return (
      <div className="grid min-h-screen content-center">
        <div className="flex justify-center">
          <motion.p
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 1 }}
            className="text-center"
          >
            Undangan tidak ada
          </motion.p>
        </div>
      </div>
    );
  }

  const activePeriodDate = new Date(data?.activePeriod);
  const eventActivePeriodDate = new Date(event && event?.activePeriod);

  // Calculate daysUntilDue
  const daysUntilDue = Math.ceil(
    (activePeriodDate.getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24)
  );

  const eventDaysUntilDue = Math.ceil(
    (eventActivePeriodDate.getTime() - new Date().getTime()) /
      (1000 * 60 * 60 * 24)
  );

  const title = data?.coverStyle?.title || "";
  const customName = data?.coverStyle?.customName || "";
  const customName2 = data?.coverStyle?.customName2 || "";

  const metaDescription =
    `${title} ${customName} ${customName2}`.trim() || window.location.host;

  const isExpired = event?.activePeriod
    ? event?.activePeriod && eventDaysUntilDue < 0
    : data?.activePeriod && daysUntilDue < 0;

  return isExpired ? (
    <div className="grid min-h-screen content-center">
      <div className="flex justify-center">
        <motion.p
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 1 }}
          className="text-center"
        >
          Undangan Sudah Kadaluarsa
        </motion.p>
      </div>
    </div>
  ) : (
    <>
      <Head>
        <link rel="icon" href={logoBahagiaDay} />
      </Head>
      <NextSeo
        title={
          data?.coverStyle?.customName2
            ? `${data?.coverStyle?.customName} ${data?.coverStyle?.customName2}`
            : data?.coverStyle?.customName || window.location.host
        }
        description={metaDescription}
        canonical={url}
        openGraph={{
          url,
          type: "website",
          title: data?.coverStyle?.customName2
            ? `${data?.coverStyle?.customName} ${data?.coverStyle?.customName2}`
            : data?.coverStyle?.customName || window.location.host,
          description: metaDescription,
          siteName: url || window.location.host,
          images: [
            {
              url:
                data?.coverStyle?.mainImage?.url ||
                data?.theme?.coverStyle?.mainImage?.url ||
                logoBahagiaDay,
              width: 800,
              height: 600,
              alt: "Gambar Utama",
              type: "image/jpeg",
            },
          ],
        }}
        twitter={{
          handle: "Bahagia.day",
          site: "Bahagia.day",
          cardType: "summary_large_image",
        }}
      />
      {showPage && (
        <>
          {data?.backsoundUrl?.includes("soundcloud.com") ? (
            <iframe
              ref={iframeRef}
              width="100%"
              height="166"
              scrolling="no"
              frameBorder="no"
              allow="autoplay"
              src={`https://w.soundcloud.com/player/?url=${data.backsoundUrl}&auto_play=false&hide_related=true&show_comments=false&show_user=false&show_reposts=false&show_teaser=false&visual=false&loop=true`}
              style={{ display: "none" }}
            />
          ) : (
            <audio
              ref={audioRef}
              src={data?.showBacksound && data?.backsound?.audioFile.url}
              loop
              autoPlay
              controls={false}
            />
          )}

          {/* <ReactPlayer
            loop
            playing={playing}
            style={{ display: "none" }}
            pip={false}
            url={data?.showBacksound ? backsoundUrl : ""}
            config={{
              youtube: {
                playerVars: {
                  origin: "https://www.youtube.com",
                },
              },
            }}
          /> */}

          <div className="bg-white w-full">
            {guestCover && cover !== "false" ? (
              <MainContainer>
                <IndexMomentCover data={data} isMomentActived={true} />
              </MainContainer>
            ) : (
              <MainContainer>
                <IndexMomentDetail momentData={data} comments={comments} />
              </MainContainer>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default Moment;

const MainContainer = styled("div", {
  "min-height": "100vh",
  display: "flex",
  "justify-content": "center",
  "max-width": "500px",
  "min-width": "auto",
  margin: "auto auto",
  // backgroundColor: 'blue',
});

export const getServerSideProps = async (context) => {
  const events = null; // Single declaration here
  try {
    const hosts = {
      "bahagia.day": "official.bahagia.day",
      "www.bahagia.day": "official.bahagia.day",
      "localhost:3000": "official-dev.bahagia.day",
    };

    const slugUrl = context.query.slug;
    const { host } = context.req.headers;
    const currentHost = hosts[host] || host;

    const urlParam = `filters[slugUrl]=${slugUrl}`;
    const activeFilter = "filters[isActive][$eq]=true";
    const brandFilter =
      currentHost === "official.bahagia.day" || currentHost === "localhost:3000"
        ? ""
        : `&filters[brand][domain]=${currentHost}`;

    // Backsound
    const backsound = "populate[backsound][populate][0]=audioFile";

    // event
    const event =
      "populate[event][fields][0]=uuid&populate[event][fields][1]=activePeriod&populate[event][populate][currentPlan][fields][0]=features";

    // GlobalStyle
    const mainStyle = "populate[globalStyle][populate][mainStyle][populate]=*";
    const globalSectionStyle =
      "populate[globalStyle][populate][sectionStyle][populate]=*";
    const typography =
      "populate[globalStyle][populate][typography][populate]=*";
    const imageAttributes =
      "populate[globalStyle][populate][imageAttributes][populate]=*";

    const globalStylePopulate = `${mainStyle}&${globalSectionStyle}&${typography}&${imageAttributes}`;

    // Cover Style
    const coverStyleMainImage =
      "populate[coverStyle][populate][mainImage][fields][0]=url";
    const coverStyleBackgroundImage =
      "populate[coverStyle][populate][backgroundImage][populate][image][fields][0]=url";
    const coverStyleMainImageRounded = `populate[coverStyle][populate][mainImageRounded][populate]=*`;
    const coverPopulate = `${coverStyleMainImage}&${coverStyleBackgroundImage}&${coverStyleMainImageRounded}`;

    // Section
    const section = "populate[section][populate][content][populate]=*";
    const sectionImage = "populate[section][populate][image][populate]=*";
    const sectionStyle = "populate[section][populate][style][populate]=*";
    const sectionPopulate = `${section}&${sectionImage}&${sectionStyle}`;

    // Theme
    const themeName = "populate[theme][fields][0]=name";
    const themeSection = "`populate[theme][populate][1]=section";
    // const themeCoverStyle = "populate[theme][populate][coverStyle][populate]=*";
    const themeCoverStyleImage =
      "populate[theme][populate][coverStyle][populate][backgroundImage][populate][image][fields][0]=url";
    // const themeCoverStyleImage = "populate[theme][populate][coverStyle][populate][backgroundImage][populate]=image";

    const themeBrandName = "populate[theme][populate][brand][fields][0]=name";
    const themeGlobalStyleSectionStyle =
      "populate[theme][populate][globalStyle][populate][sectionStyle][populate]=*";
    const themeGlobalStyleMainStyle =
      "populate[theme][populate][globalStyle][populate][mainStyle][populate]=*";
    const themeGlobalStyleTypography =
      "populate[theme][populate][globalStyle][populate][typography][populate]=*";
    const themeGlobalStyleImageAttributes =
      "populate[theme][populate][globalStyle][populate][imageAttributes][populate]=*";

    // const themePopulate = `${themeName}&${themeSection}&${themeCoverStyle}&${themeBrandName}&${themeGlobalStyleSectionStyle}&${themeGlobalStyleMainStyle}&${themeGlobalStyleTypography}&${themeGlobalStyleImageAttributes}`;
    const themePopulate = `${themeName}&${themeSection}&${themeCoverStyleImage}&${themeBrandName}&${themeGlobalStyleSectionStyle}&${themeGlobalStyleMainStyle}&${themeGlobalStyleTypography}&${themeGlobalStyleImageAttributes}`;

    const allPopulation = `${backsound}&${globalStylePopulate}&${coverPopulate}&${sectionPopulate}&${themePopulate}&${event}`;
    // const paramsKey = `${allPopulation}&${urlParam}&${activeFilter}${brandFilter}`;
    const paramsKey = `${allPopulation}&${urlParam}&${activeFilter}`;

    const fetchURL = `${apiURL}/api/pages?${paramsKey}`;
    

    const response = await fetch(fetchURL, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${process.env.API_SECRET}`,
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error(`Error fetching data: ${response.status}`);
    }

    const dataResponse = await response.json();
    const momentData = dataResponse.data || null;

    let comments = null;
    let events = null;

    if (momentData) {
      const commentsResponse = await fetch(
        `${apiURL}/api/comments?filters[page][uuid]=${momentData[0].uuid}&sort[0]=createdAt:desc`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${process.env.API_SECRET}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (commentsResponse.ok) {
        const commentsData = await commentsResponse.json();
        comments = commentsData.data;
      }

      // console.log("momentData: ", momentData[0].event);

      // Check if events are available and fetch if true
      if (momentData[0]?.event) {
        const fetchURL = `${apiURL}/api/events?filters[uuid]=${momentData[0]?.event?.uuid}&fields[0]=uuid&fields[1]=activePeriod&populate[currentPlan][fields][0]=features`;
        const eventsResponse = await fetch(fetchURL, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${process.env.API_SECRET}`,
            "Content-Type": "application/json",
          },
        });

        if (eventsResponse.ok) {
          const eventsData = await eventsResponse.json();
          events = eventsData?.data?.[0];
        }
      }
    }

    return {
      props: {
        momentData,
        comments,
        events,
        url: context.req.headers.host + context.req.url,
        cover: context.query.cover || "true",
      },
    };
  } catch (error) {
    // eslint-disable-next-line no-console
    // console.error("Error in getServerSideProps:", error);

    return {
      props: {
        momentData: null,
        comments: null,
        events,
        url: context.req.headers.host + context.req.url,
        cover: context.query.cover || "true",
      },
    };
  }
};
