/* eslint-disable no-unused-expressions */
// eslint-disable-next-line import/no-extraneous-dependencies

import dynamic from 'next/dynamic';
// import { WeddingCover } from './1/WeddingCover';

const MomentDetail1 = dynamic(() => import('./1/MomentDetail'));
const WeddingDetail2 = dynamic(() => import('./2/MomentDetail'));

// eslint-disable-next-line import/prefer-default-export
export const IndexMomentDetail = ({
  momentData,
  wishes,
  isMomentThemeSampel,
  comments,
}) => {
  const layoutType = 'type1';

  // console.log('themeCode: ', themeCode);
  return (
    <>
      {(layoutType === 'type1' || !layoutType) && (
        <MomentDetail1
          momentData={momentData}
          wishes={wishes}
          isMomentThemeSampel={isMomentThemeSampel}
          comments={comments}
        />
      )}
      {layoutType === 'type2' && (
        <WeddingDetail2
          momentData={momentData}
          wishes={wishes}
          isMomentThemeSampel={isMomentThemeSampel}
          comments={comments}
        />
      )}
      {/* {themeCode === 2 && (
        <WeddingCover2
          groomNickName={groomNickName}
          brideNickName={brideNickName}
          guestName={guestName}
        />
      )} */}
    </>
  );
};
